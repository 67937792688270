<!-- eslint-disable max-len -->
<div class="top-margin rw-main-content"></div>

<div id="invoice-page"></div>

<div class="hide">
  <!-- Header - Start -->
  <!-- <header
    class="grid pb-3"
    id="header"
  ></header> -->

  <!-- Invoice & Buyer Info - Start -->
  <!-- <div
    class="grid"
    id="customer-info"
  >
    <div class="col-3">
      <div class="font-medium">Bill to</div>
      <div class="font-medium text-neutral-60 mb-1">العميل</div>
      <div>مؤسسة عبدالرحمن هلال للمقاولات والترقيع شركة شخص واحد</div>
      <div>
        3575 Al Feidh, Irqah Unit 123456789011 No Kingdom of Saudi Arabia
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Buyer Tax Number</div>
          <div class="font-medium text-neutral-60 mb-1">
            رقم السجل الضريبي للعميل
          </div>
          <div>264867547786453</div>
        </div>
        <div>
          <div class="font-medium">Buyer ID: CR</div>
          <div class="font-medium text-neutral-60 mb-1">
            معرّف العميل: سجل تجاري
          </div>
          <div>1234567890</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Invoice Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم الفاتورة</div>
          <div>{{ invoice.id }}</div>
        </div>
        <div>
          <div class="font-medium">Invoice Issue Date</div>
          <div class="font-medium text-neutral-60 mb-1">
            تاريخ إصدار الفاتورة
          </div>
          <div>23 Dec 2024, 07:35:59 AM</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2 text-right">
        <div>
          <div class="font-medium">Reference No.</div>
          <div class="font-medium text-neutral-60 mb-1">رقم مرجعي</div>
          <div>11223344</div>
        </div>
        <div>
          <div class="font-medium">Supply Date</div>
          <div class="font-medium text-neutral-60 mb-1">تاريخ التسليم</div>
          <div>23 Dec 2024</div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Price Highlight - Start -->
  <div class="text-right pt-2 border-top-1 border-neutral-20">
    <div class="font-medium">Total Due</div>
    <div class="font-medium text-neutral-60">إجمالي المستحق</div>
    <div class="flex gap-1 align-items-center justify-content-end">
      <div class="line-height-1">
        <div class="rw__fs-9">SAR</div>
        <div class="rw__fs-11 text-neutral-60">ر.س</div>
      </div>
      <div class="rw__fs-5">2300.00</div>
    </div>
  </div>

  <!-- Invoice Item Line Table - Start-->
  <div class="pb-2 pt-3">
    <table class="print-table print-border-table print-item-table">
      <thead>
        <tr>
          <th class="text-left">
            <div class="font-medium text-neutral-60">#</div>
          </th>
          <th>
            ¡
            <div class="font-medium">Item / Description</div>
            <div class="font-medium text-neutral-60">الوصف / المنتج</div>
          </th>
          <th>
            <div class="font-medium">Price</div>
            <div class="font-medium text-neutral-60">سعر الوحدة</div>
          </th>
          <th>
            <div class="font-medium">Quantity</div>
            <div class="font-medium text-neutral-60">الكمية</div>
          </th>
          <th>
            <div class="font-medium">Discount</div>
            <div class="font-medium text-neutral-60">خصم</div>
          </th>
          <th>
            <div class="font-medium">Taxable Amount</div>
            <div class="font-medium text-neutral-60">المبلغ الخاضع للضريبة</div>
          </th>
          <th>
            <div class="font-medium">VAT</div>
            <div class="font-medium text-neutral-60">القيمة المضافة</div>
          </th>
          <th>
            <div class="font-medium">Amount</div>
            <div class="font-medium text-neutral-60">المجموع</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left">
            <div class="font-medium text-neutral-60">1</div>
          </td>
          <td class="text-neutral-60">
            <div class="font-semibold">
              <div>Basic Plan (1 year)</div>
              <div>اشتراك باقة أساسية (سنة)</div>
            </div>
            <div>1 Jan 2023-31 Dec 2024</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>x3</div>
          </td>
          <td>
            <div>-4000</div>
            <div class="text-neutral-60">60%</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>300</div>
            <div class="text-neutral-60">15%</div>
          </td>
          <td>
            <div>2300</div>
          </td>
        </tr>
        <tr>
          <td class="text-left">
            <div class="font-medium text-neutral-60">1</div>
          </td>
          <td class="text-neutral-60">
            <div class="font-semibold">
              <div>Basic Plan (1 year)</div>
              <div>اشتراك باقة أساسية (سنة)</div>
            </div>
            <div>1 Jan 2023-31 Dec 2024</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>x3</div>
          </td>
          <td>
            <div>-4000</div>
            <div class="text-neutral-60">60%</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>300</div>
            <div class="text-neutral-60">15%</div>
          </td>
          <td>
            <div>2300</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Notes & Calculation Summary - Start -->
  <div class="grid">
    <div class="col-6">
      <div class="font-medium">Notes</div>
      <div class="font-medium text-neutral-60 mb-1">ملاحظات</div>
      <div>-</div>
    </div>
    <div class="col-6">
      <table class="print-table print-item-table">
        <tbody>
          <tr>
            <td>
              <div class="font-medium">Subtotal</div>
              <div class="font-medium text-neutral-60">
                الإجمالي غير شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>6000</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Discount</div>
              <div class="font-medium text-neutral-60">إجمالي الخصم</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>-4000</div>
              <div class="text-neutral-60">60%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Taxable Amount</div>
              <div class="font-medium text-neutral-60">
                إجمالي المبلغ الخاضع للضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>2000</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total VAT</div>
              <div class="font-medium text-neutral-60">
                إجمالي ضريبة القيمة المضافة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>300</div>
              <div class="text-neutral-60">15%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total</div>
              <div class="font-medium text-neutral-60">
                الإجمالي شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-medium">2300</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9">
        <img
          src="/assets/images/invoice-pdf-qr.svg"
          alt=""
        />
        <div class="mt-2">
          This QR code is encoded as per ZATCA's invoicing requirements
        </div>
        <div>
          رمز الإستجابة السريعة مشفّر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
          للفوترة الإلكترونية
        </div>
      </div>
      <div class="col-3">
        <div class="text-right">
          Page 1 of 3
          @if (zatcaCompleted) {
            - {{ invoice.id }}
          }
        </div>
      </div>
    </div>
  </footer>
</div>
<!-- Invoice Page - End -->

<!-- <div class="py-6"></div> -->

<!-- Bank Transfer Information - Start -->
<div
  class="print-wrapper page-break body"
  id="bank-transfer"
>
  @if (
    !zatcaCompleted && invoice?.zatcaStatus !== zatcaStatusConstant.PreZatca
  ) {
    <div class="watermark">
      <div>DRAFT INVOICE</div>
      <div>فاتـــــــــــــــــــــورة مسودّة</div>
    </div>
  }
  <!-- Header - Start -->
  <header class="grid pb-3">
    <div class="col-5">
      <div class="fs-22 font-semibold">Tax Invoice</div>
      <div class="rw__fs-7 font-normal text-neutral-60">فاتورة ضريبية</div>
    </div>
    <div class="col-2">
      <div class="max-auto flex justify-content-center">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_29_955)">
            <path
              d="M24.1216 2.00145H11.2734C10.9199 1.99746 10.5704 2.07632 10.2533 2.23163C9.93627 2.38694 9.66059 2.61432 9.44866 2.8953C9.23673 3.17628 9.09453 3.50293 9.03362 3.84873C8.9727 4.19453 8.99479 4.54972 9.09808 4.88545L20.0611 39.5575C20.2108 40.0132 20.5018 40.4102 20.8925 40.6918C21.2832 40.9733 21.7536 41.1249 22.2364 41.1249C22.7191 41.1249 23.1895 40.9733 23.5802 40.6918C23.9709 40.4102 24.262 40.0132 24.4117 39.5575L32.6938 13.3575C33.0982 12.035 33.1831 10.6366 32.9419 9.27549C32.7006 7.91438 32.1399 6.62883 31.3051 5.52296C30.4703 4.4171 29.385 3.52201 28.137 2.91021C26.889 2.2984 25.5134 1.98708 24.1216 2.00145Z"
              fill="#78CAD5"
            />
            <path
              opacity="0.9"
              d="M30.2648 6.36148H16.8163C16.4977 6.35826 16.1827 6.42958 15.897 6.56965C15.6112 6.70973 15.3627 6.91462 15.1715 7.16777C14.9803 7.42092 14.8518 7.7152 14.7964 8.02681C14.741 8.33843 14.7602 8.65861 14.8525 8.96148L26.1157 44.5815C26.2502 44.9935 26.5127 45.3525 26.8655 45.6072C27.2183 45.8619 27.6433 45.999 28.0794 45.999C28.5156 45.999 28.9406 45.8619 29.2934 45.6072C29.6462 45.3525 29.9087 44.9935 30.0432 44.5815L38.6255 17.4395C39.021 16.1495 39.1047 14.7852 38.8698 13.4571C38.6349 12.129 38.0882 10.8746 37.2738 9.79554C36.4595 8.71651 35.4004 7.84327 34.1827 7.24662C32.9649 6.64997 31.6227 6.34673 30.2648 6.36148Z"
              fill="#00A5A3"
            />
          </g>
          <defs>
            <clipPath id="clip0_29_955">
              <rect
                width="30"
                height="43.998"
                fill="white"
                transform="translate(9 2.00098)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <div class="col-5 text-neutral-60 text-right">
      <div>شركة رواء التقنية لتقنية المعلومات شركة شخص واحد ذ.م.م</div>
      <div>٣٢٥٩، انس بن مالك، ٨٣٨٤ حي الملقا، ١٣٥٢٢ الرياض</div>
      <div>المملكة العربية السعودية</div>
      <div>رقم السجل التجاري:1010948357</div>
      <div>رقم التسجيل الضريبي:310188259700003</div>
    </div>
  </header>
  <!-- Header - End -->

  <!-- Page Title - Start -->
  <div
    class="flex justify-content-between rw__fs-11 font-bold pb-3 border-neutral-20 border-bottom-1"
  >
    <div>Bank Transfer Information</div>
    <div>معلومات الحوالات البنكية</div>
  </div>
  <!-- Page Title - End -->

  <!-- Beneficiary Details - Start -->
  <div class="py-3">
    <div class="flex justify-content-between font-medium">
      <div>Beneficiary Name</div>
      <div>إسم المستفيد</div>
    </div>
    <div class="flex justify-content-between mt-1">
      <div>Rewaa Technology Limited Liability Company</div>
      <div>شركة رواء لتقنية المعلومات المحدودة</div>
    </div>
  </div>
  <!-- Beneficiary Details - End -->

  <!-- Bank Details - Start -->
  <div>
    <table class="print-table print-border-table bank-table">
      <thead>
        <tr>
          <th class="text-left"><div>Bank</div></th>
          <th>
            <div class="flex justify-content-between">
              <div>IBAN</div>
              <div>آيبان</div>
            </div>
          </th>
          <th>
            <div>البنك</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left"><div>Al Rajhi Bank</div></td>
          <td>
            <div>SA38 8000 0504 6080 1025 5470</div>
          </td>
          <td>
            <div>مصرف الراجحي</div>
          </td>
        </tr>
        <tr>
          <td class="text-left"><div>Riyad Bank</div></td>
          <td>
            <div>SA74 2000 0002 4116 3936 9940</div>
          </td>
          <td>
            <div>بنك الرياض</div>
          </td>
        </tr>
        <tr>
          <td class="text-left"><div>Saudi National Bank (SNB)</div></td>
          <td>
            <div>SA37 1000 0019 5000 0006 6907</div>
          </td>
          <td>
            <div>البنك الأهلي السعودي</div>
          </td>
        </tr>
        <tr>
          <td class="text-left"><div>SAIB Bank</div></td>
          <td>
            <div>SA85 6500 0000 1016 9293 9001</div>
          </td>
          <td>
            <div>البنك السعودي للإستثمار</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Bank Details - End -->

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9"></div>
      <div class="col-3">
        <div class="text-right">
          Page {{ info.taxInvoicePages + 1 }} of
          {{ info.taxInvoicePages + 2 }} -
          {{ getInvoiceId }}
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer - End -->
</div>
<!-- Bank Transfer Information - End -->

<!-- <div class="py-6"></div> -->

<!-- Terms & Conditions - Start -->
<div
  class="print-wrapper page-break body"
  id="T&C"
>
  @if (
    !zatcaCompleted && invoice?.zatcaStatus !== zatcaStatusConstant.PreZatca
  ) {
    <div class="watermark">
      <div>DRAFT INVOICE</div>
      <div>فاتـــــــــــــــــــــورة مسودّة</div>
    </div>
  }
  <!-- Header - Start -->
  <header class="grid pb-3">
    <div class="col-5">
      <div class="fs-22 font-semibold">Tax Invoice</div>
      <div class="rw__fs-7 font-normal text-neutral-60">فاتورة ضريبية</div>
    </div>
    <div class="col-2">
      <div class="max-auto flex justify-content-center">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_29_955)">
            <path
              d="M24.1216 2.00145H11.2734C10.9199 1.99746 10.5704 2.07632 10.2533 2.23163C9.93627 2.38694 9.66059 2.61432 9.44866 2.8953C9.23673 3.17628 9.09453 3.50293 9.03362 3.84873C8.9727 4.19453 8.99479 4.54972 9.09808 4.88545L20.0611 39.5575C20.2108 40.0132 20.5018 40.4102 20.8925 40.6918C21.2832 40.9733 21.7536 41.1249 22.2364 41.1249C22.7191 41.1249 23.1895 40.9733 23.5802 40.6918C23.9709 40.4102 24.262 40.0132 24.4117 39.5575L32.6938 13.3575C33.0982 12.035 33.1831 10.6366 32.9419 9.27549C32.7006 7.91438 32.1399 6.62883 31.3051 5.52296C30.4703 4.4171 29.385 3.52201 28.137 2.91021C26.889 2.2984 25.5134 1.98708 24.1216 2.00145Z"
              fill="#78CAD5"
            />
            <path
              opacity="0.9"
              d="M30.2648 6.36148H16.8163C16.4977 6.35826 16.1827 6.42958 15.897 6.56965C15.6112 6.70973 15.3627 6.91462 15.1715 7.16777C14.9803 7.42092 14.8518 7.7152 14.7964 8.02681C14.741 8.33843 14.7602 8.65861 14.8525 8.96148L26.1157 44.5815C26.2502 44.9935 26.5127 45.3525 26.8655 45.6072C27.2183 45.8619 27.6433 45.999 28.0794 45.999C28.5156 45.999 28.9406 45.8619 29.2934 45.6072C29.6462 45.3525 29.9087 44.9935 30.0432 44.5815L38.6255 17.4395C39.021 16.1495 39.1047 14.7852 38.8698 13.4571C38.6349 12.129 38.0882 10.8746 37.2738 9.79554C36.4595 8.71651 35.4004 7.84327 34.1827 7.24662C32.9649 6.64997 31.6227 6.34673 30.2648 6.36148Z"
              fill="#00A5A3"
            />
          </g>
          <defs>
            <clipPath id="clip0_29_955">
              <rect
                width="30"
                height="43.998"
                fill="white"
                transform="translate(9 2.00098)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <div class="col-5 text-neutral-60 text-right">
      <div>شركة رواء التقنية لتقنية المعلومات شركة شخص واحد ذ.م.م</div>
      <div>٣٢٥٩، انس بن مالك، ٨٣٨٤ حي الملقا، ١٣٥٢٢ الرياض</div>
      <div>المملكة العربية السعودية</div>
      <div>رقم السجل التجاري:1010948357</div>
      <div>رقم التسجيل الضريبي:310188259700003</div>
    </div>
  </header>
  <!-- Header - End -->

  <!-- Page Title - Start -->
  <div class="grid pb-2 border-neutral-20 border-bottom-1 align-items-end">
    <div class="col-5">
      <div class="rw__fs-11 font-bold">
        Terms &amp; Conditions, and Privacy Policy
      </div>
      <div class="text-neutral-60">
        Scan QR to go through all of the terms &amp; conditions
      </div>
    </div>
    <div class="flex justify-content-center mx-auto">
      <svg
        width="90"
        height="90"
        viewBox="0 0 65 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_189_611)">
          <path
            d="M1.92222 0H0.5V1.42222H1.92222V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 0H1.92222V1.42222H3.34444V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 0H3.34444V1.42222H4.76667V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 0H4.76667V1.42222H6.18889V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 0H6.18889V1.42222H7.61111V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 0H7.61111V1.42222H9.03333V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 0H9.03333V1.42222H10.4556V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 0H11.8778V1.42222H13.3V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 0H16.1444V1.42222H17.5667V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 0H17.5667V1.42222H18.9889V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 0H18.9889V1.42222H20.4111V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 0H20.4111V1.42222H21.8333V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 0H23.2556V1.42222H24.6778V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 0H33.2111V1.42222H34.6333V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 0H34.6333V1.42222H36.0556V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 0H38.9V1.42222H40.3222V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 0H46.0111V1.42222H47.4333V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 0H47.4333V1.42222H48.8556V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 0H51.7V1.42222H53.1222V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 0H54.5444V1.42222H55.9667V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 0H55.9667V1.42222H57.3889V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 0H57.3889V1.42222H58.8111V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 0H58.8111V1.42222H60.2333V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 0H60.2333V1.42222H61.6556V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 0H61.6556V1.42222H63.0778V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 0H63.0778V1.42222H64.5V0Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 1.42222H0.5V2.84444H1.92222V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 1.42222H9.03333V2.84444H10.4556V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 1.42222H13.3V2.84444H14.7222V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 1.42222H16.1444V2.84444H17.5667V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 1.42222H17.5667V2.84444H18.9889V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 1.42222H20.4111V2.84444H21.8333V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 1.42222H24.6778V2.84444H26.1V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 1.42222H26.1V2.84444H27.5222V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 1.42222H30.3667V2.84444H31.7889V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 1.42222H34.6333V2.84444H36.0556V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 1.42222H36.0556V2.84444H37.4778V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 1.42222H38.9V2.84444H40.3222V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 1.42222H43.1667V2.84444H44.5889V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 1.42222H47.4333V2.84444H48.8556V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 1.42222H50.2778V2.84444H51.7V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 1.42222H54.5444V2.84444H55.9667V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 1.42222H63.0778V2.84444H64.5V1.42222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 2.84444H0.5V4.26667H1.92222V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 2.84444H3.34444V4.26667H4.76667V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 2.84444H4.76667V4.26667H6.18889V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 2.84444H6.18889V4.26667H7.61111V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 2.84444H9.03333V4.26667H10.4556V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 2.84444H14.7222V4.26667H16.1444V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 2.84444H16.1444V4.26667H17.5667V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 2.84444H20.4111V4.26667H21.8333V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 2.84444H23.2556V4.26667H24.6778V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 2.84444H24.6778V4.26667H26.1V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 2.84444H26.1V4.26667H27.5222V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 2.84444H28.9444V4.26667H30.3667V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 2.84444H31.7889V4.26667H33.2111V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 2.84444H34.6333V4.26667H36.0556V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 2.84444H36.0556V4.26667H37.4778V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 2.84444H37.4778V4.26667H38.9V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 2.84444H38.9V4.26667H40.3222V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 2.84444H40.3222V4.26667H41.7444V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 2.84444H41.7444V4.26667H43.1667V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 2.84444H43.1667V4.26667H44.5889V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 2.84444H46.0111V4.26667H47.4333V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 2.84444H50.2778V4.26667H51.7V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 2.84444H54.5444V4.26667H55.9667V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 2.84444H57.3889V4.26667H58.8111V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 2.84444H58.8111V4.26667H60.2333V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 2.84444H60.2333V4.26667H61.6556V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 2.84444H63.0778V4.26667H64.5V2.84444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 4.26667H0.5V5.68889H1.92222V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 4.26667H3.34444V5.68889H4.76667V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 4.26667H4.76667V5.68889H6.18889V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 4.26667H6.18889V5.68889H7.61111V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 4.26667H9.03333V5.68889H10.4556V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 4.26667H11.8778V5.68889H13.3V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 4.26667H13.3V5.68889H14.7222V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 4.26667H20.4111V5.68889H21.8333V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 4.26667H26.1V5.68889H27.5222V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 4.26667H28.9444V5.68889H30.3667V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 4.26667H31.7889V5.68889H33.2111V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 4.26667H33.2111V5.68889H34.6333V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 4.26667H34.6333V5.68889H36.0556V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 4.26667H36.0556V5.68889H37.4778V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 4.26667H37.4778V5.68889H38.9V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 4.26667H38.9V5.68889H40.3222V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 4.26667H40.3222V5.68889H41.7444V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 4.26667H41.7444V5.68889H43.1667V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 4.26667H43.1667V5.68889H44.5889V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 4.26667H44.5889V5.68889H46.0111V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 4.26667H46.0111V5.68889H47.4333V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 4.26667H47.4333V5.68889H48.8556V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 4.26667H50.2778V5.68889H51.7V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 4.26667H51.7V5.68889H53.1222V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 4.26667H54.5444V5.68889H55.9667V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 4.26667H57.3889V5.68889H58.8111V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 4.26667H58.8111V5.68889H60.2333V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 4.26667H60.2333V5.68889H61.6556V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 4.26667H63.0778V5.68889H64.5V4.26667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 5.68889H0.5V7.11111H1.92222V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 5.68889H3.34444V7.11111H4.76667V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 5.68889H4.76667V7.11111H6.18889V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 5.68889H6.18889V7.11111H7.61111V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 5.68889H9.03333V7.11111H10.4556V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 5.68889H14.7222V7.11111H16.1444V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 5.68889H18.9889V7.11111H20.4111V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 5.68889H20.4111V7.11111H21.8333V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 5.68889H21.8333V7.11111H23.2556V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 5.68889H24.6778V7.11111H26.1V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 5.68889H28.9444V7.11111H30.3667V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 5.68889H30.3667V7.11111H31.7889V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 5.68889H31.7889V7.11111H33.2111V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 5.68889H33.2111V7.11111H34.6333V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 5.68889H34.6333V7.11111H36.0556V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 5.68889H37.4778V7.11111H38.9V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 5.68889H38.9V7.11111H40.3222V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 5.68889H43.1667V7.11111H44.5889V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 5.68889H48.8556V7.11111H50.2778V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 5.68889H50.2778V7.11111H51.7V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 5.68889H51.7V7.11111H53.1222V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 5.68889H54.5444V7.11111H55.9667V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 5.68889H57.3889V7.11111H58.8111V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 5.68889H58.8111V7.11111H60.2333V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 5.68889H60.2333V7.11111H61.6556V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 5.68889H63.0778V7.11111H64.5V5.68889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 7.11111H0.5V8.53333H1.92222V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 7.11111H9.03333V8.53333H10.4556V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 7.11111H11.8778V8.53333H13.3V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 7.11111H14.7222V8.53333H16.1444V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 7.11111H16.1444V8.53333H17.5667V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 7.11111H18.9889V8.53333H20.4111V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 7.11111H21.8333V8.53333H23.2556V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 7.11111H23.2556V8.53333H24.6778V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 7.11111H24.6778V8.53333H26.1V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 7.11111H28.9444V8.53333H30.3667V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 7.11111H34.6333V8.53333H36.0556V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 7.11111H37.4778V8.53333H38.9V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 7.11111H40.3222V8.53333H41.7444V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 7.11111H41.7444V8.53333H43.1667V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 7.11111H43.1667V8.53333H44.5889V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 7.11111H46.0111V8.53333H47.4333V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 7.11111H54.5444V8.53333H55.9667V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 7.11111H63.0778V8.53333H64.5V7.11111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 8.53333H0.5V9.95556H1.92222V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 8.53333H1.92222V9.95556H3.34444V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 8.53333H3.34444V9.95556H4.76667V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 8.53333H4.76667V9.95556H6.18889V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 8.53333H6.18889V9.95556H7.61111V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 8.53333H7.61111V9.95556H9.03333V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 8.53333H9.03333V9.95556H10.4556V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 8.53333H11.8778V9.95556H13.3V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 8.53333H14.7222V9.95556H16.1444V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 8.53333H17.5667V9.95556H18.9889V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 8.53333H20.4111V9.95556H21.8333V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 8.53333H23.2556V9.95556H24.6778V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 8.53333H26.1V9.95556H27.5222V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 8.53333H28.9444V9.95556H30.3667V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 8.53333H31.7889V9.95556H33.2111V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 8.53333H34.6333V9.95556H36.0556V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 8.53333H37.4778V9.95556H38.9V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 8.53333H40.3222V9.95556H41.7444V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 8.53333H43.1667V9.95556H44.5889V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 8.53333H46.0111V9.95556H47.4333V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 8.53333H48.8556V9.95556H50.2778V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 8.53333H51.7V9.95556H53.1222V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 8.53333H54.5444V9.95556H55.9667V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 8.53333H55.9667V9.95556H57.3889V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 8.53333H57.3889V9.95556H58.8111V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 8.53333H58.8111V9.95556H60.2333V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 8.53333H60.2333V9.95556H61.6556V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 8.53333H61.6556V9.95556H63.0778V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 8.53333H63.0778V9.95556H64.5V8.53333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 9.95556H13.3V11.3778H14.7222V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 9.95556H14.7222V11.3778H16.1444V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 9.95556H17.5667V11.3778H18.9889V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 9.95556H18.9889V11.3778H20.4111V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 9.95556H20.4111V11.3778H21.8333V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 9.95556H21.8333V11.3778H23.2556V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 9.95556H23.2556V11.3778H24.6778V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 9.95556H26.1V11.3778H27.5222V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 9.95556H27.5222V11.3778H28.9444V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 9.95556H28.9444V11.3778H30.3667V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 9.95556H34.6333V11.3778H36.0556V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 9.95556H37.4778V11.3778H38.9V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 9.95556H38.9V11.3778H40.3222V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 9.95556H41.7444V11.3778H43.1667V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 9.95556H48.8556V11.3778H50.2778V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 9.95556H51.7V11.3778H53.1222V9.95556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 11.3778H7.61111V12.8H9.03333V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 11.3778H9.03333V12.8H10.4556V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 11.3778H11.8778V12.8H13.3V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 11.3778H13.3V12.8H14.7222V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 11.3778H16.1444V12.8H17.5667V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 11.3778H18.9889V12.8H20.4111V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 11.3778H20.4111V12.8H21.8333V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 11.3778H23.2556V12.8H24.6778V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 11.3778H24.6778V12.8H26.1V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 11.3778H28.9444V12.8H30.3667V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 11.3778H30.3667V12.8H31.7889V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 11.3778H31.7889V12.8H33.2111V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 11.3778H33.2111V12.8H34.6333V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 11.3778H34.6333V12.8H36.0556V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 11.3778H40.3222V12.8H41.7444V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 11.3778H44.5889V12.8H46.0111V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 11.3778H46.0111V12.8H47.4333V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 11.3778H50.2778V12.8H51.7V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 11.3778H51.7V12.8H53.1222V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 11.3778H53.1222V12.8H54.5444V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 11.3778H55.9667V12.8H57.3889V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 11.3778H58.8111V12.8H60.2333V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 11.3778H63.0778V12.8H64.5V11.3778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 12.8H0.5V14.2222H1.92222V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 12.8H1.92222V14.2222H3.34444V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 12.8H3.34444V14.2222H4.76667V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 12.8H4.76667V14.2222H6.18889V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 12.8H7.61111V14.2222H9.03333V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 12.8H10.4556V14.2222H11.8778V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 12.8H13.3V14.2222H14.7222V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 12.8H14.7222V14.2222H16.1444V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 12.8H17.5667V14.2222H18.9889V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 12.8H20.4111V14.2222H21.8333V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 12.8H26.1V14.2222H27.5222V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 12.8H33.2111V14.2222H34.6333V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 12.8H37.4778V14.2222H38.9V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 12.8H40.3222V14.2222H41.7444V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 12.8H41.7444V14.2222H43.1667V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 12.8H50.2778V14.2222H51.7V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 12.8H51.7V14.2222H53.1222V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 12.8H61.6556V14.2222H63.0778V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 12.8H63.0778V14.2222H64.5V12.8Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 14.2222H0.5V15.6444H1.92222V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 14.2222H4.76667V15.6444H6.18889V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 14.2222H6.18889V15.6444H7.61111V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 14.2222H7.61111V15.6444H9.03333V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 14.2222H9.03333V15.6444H10.4556V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 14.2222H14.7222V15.6444H16.1444V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 14.2222H20.4111V15.6444H21.8333V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 14.2222H26.1V15.6444H27.5222V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 14.2222H30.3667V15.6444H31.7889V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 14.2222H38.9V15.6444H40.3222V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 14.2222H40.3222V15.6444H41.7444V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 14.2222H43.1667V15.6444H44.5889V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 14.2222H46.0111V15.6444H47.4333V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 14.2222H47.4333V15.6444H48.8556V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 14.2222H53.1222V15.6444H54.5444V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 14.2222H58.8111V15.6444H60.2333V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 14.2222H60.2333V15.6444H61.6556V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 14.2222H61.6556V15.6444H63.0778V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 14.2222H63.0778V15.6444H64.5V14.2222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 15.6444H0.5V17.0667H1.92222V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 15.6444H6.18889V17.0667H7.61111V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 15.6444H7.61111V17.0667H9.03333V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 15.6444H13.3V17.0667H14.7222V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 15.6444H14.7222V17.0667H16.1444V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 15.6444H21.8333V17.0667H23.2556V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 15.6444H24.6778V17.0667H26.1V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 15.6444H28.9444V17.0667H30.3667V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 15.6444H30.3667V17.0667H31.7889V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 15.6444H33.2111V17.0667H34.6333V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 15.6444H34.6333V17.0667H36.0556V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 15.6444H38.9V17.0667H40.3222V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 15.6444H43.1667V17.0667H44.5889V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 15.6444H44.5889V17.0667H46.0111V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 15.6444H46.0111V17.0667H47.4333V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 15.6444H47.4333V17.0667H48.8556V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 15.6444H50.2778V17.0667H51.7V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 15.6444H51.7V17.0667H53.1222V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 15.6444H53.1222V17.0667H54.5444V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 15.6444H54.5444V17.0667H55.9667V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 15.6444H55.9667V17.0667H57.3889V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 15.6444H58.8111V17.0667H60.2333V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 15.6444H61.6556V17.0667H63.0778V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 15.6444H63.0778V17.0667H64.5V15.6444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 17.0667H3.34444V18.4889H4.76667V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 17.0667H7.61111V18.4889H9.03333V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 17.0667H9.03333V18.4889H10.4556V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 17.0667H10.4556V18.4889H11.8778V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 17.0667H11.8778V18.4889H13.3V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 17.0667H13.3V18.4889H14.7222V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 17.0667H14.7222V18.4889H16.1444V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 17.0667H16.1444V18.4889H17.5667V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 17.0667H18.9889V18.4889H20.4111V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 17.0667H24.6778V18.4889H26.1V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 17.0667H26.1V18.4889H27.5222V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 17.0667H31.7889V18.4889H33.2111V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 17.0667H37.4778V18.4889H38.9V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 17.0667H40.3222V18.4889H41.7444V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 17.0667H48.8556V18.4889H50.2778V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 17.0667H51.7V18.4889H53.1222V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 17.0667H53.1222V18.4889H54.5444V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 17.0667H54.5444V18.4889H55.9667V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 17.0667H55.9667V18.4889H57.3889V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 17.0667H58.8111V18.4889H60.2333V17.0667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 18.4889H0.5V19.9111H1.92222V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 18.4889H3.34444V19.9111H4.76667V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 18.4889H4.76667V19.9111H6.18889V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 18.4889H6.18889V19.9111H7.61111V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 18.4889H10.4556V19.9111H11.8778V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 18.4889H13.3V19.9111H14.7222V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 18.4889H17.5667V19.9111H18.9889V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 18.4889H23.2556V19.9111H24.6778V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 18.4889H27.5222V19.9111H28.9444V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 18.4889H28.9444V19.9111H30.3667V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 18.4889H33.2111V19.9111H34.6333V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 18.4889H40.3222V19.9111H41.7444V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 18.4889H41.7444V19.9111H43.1667V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 18.4889H43.1667V19.9111H44.5889V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 18.4889H44.5889V19.9111H46.0111V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 18.4889H46.0111V19.9111H47.4333V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 18.4889H47.4333V19.9111H48.8556V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 18.4889H48.8556V19.9111H50.2778V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 18.4889H51.7V19.9111H53.1222V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 18.4889H60.2333V19.9111H61.6556V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 18.4889H61.6556V19.9111H63.0778V18.4889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 19.9111H0.5V21.3333H1.92222V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 19.9111H3.34444V21.3333H4.76667V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 19.9111H4.76667V21.3333H6.18889V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 19.9111H7.61111V21.3333H9.03333V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 19.9111H9.03333V21.3333H10.4556V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 19.9111H10.4556V21.3333H11.8778V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 19.9111H11.8778V21.3333H13.3V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 19.9111H13.3V21.3333H14.7222V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 19.9111H14.7222V21.3333H16.1444V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 19.9111H16.1444V21.3333H17.5667V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 19.9111H18.9889V21.3333H20.4111V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 19.9111H23.2556V21.3333H24.6778V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 19.9111H24.6778V21.3333H26.1V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 19.9111H26.1V21.3333H27.5222V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 19.9111H27.5222V21.3333H28.9444V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 19.9111H28.9444V21.3333H30.3667V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 19.9111H31.7889V21.3333H33.2111V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 19.9111H33.2111V21.3333H34.6333V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 19.9111H34.6333V21.3333H36.0556V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 19.9111H37.4778V21.3333H38.9V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 19.9111H38.9V21.3333H40.3222V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 19.9111H40.3222V21.3333H41.7444V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 19.9111H41.7444V21.3333H43.1667V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 19.9111H43.1667V21.3333H44.5889V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 19.9111H44.5889V21.3333H46.0111V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 19.9111H46.0111V21.3333H47.4333V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 19.9111H47.4333V21.3333H48.8556V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 19.9111H50.2778V21.3333H51.7V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 19.9111H57.3889V21.3333H58.8111V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 19.9111H61.6556V21.3333H63.0778V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 19.9111H63.0778V21.3333H64.5V19.9111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 21.3333H0.5V22.7556H1.92222V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 21.3333H1.92222V22.7556H3.34444V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 21.3333H4.76667V22.7556H6.18889V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 21.3333H6.18889V22.7556H7.61111V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 21.3333H10.4556V22.7556H11.8778V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 21.3333H13.3V22.7556H14.7222V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 21.3333H16.1444V22.7556H17.5667V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 21.3333H17.5667V22.7556H18.9889V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 21.3333H20.4111V22.7556H21.8333V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 21.3333H21.8333V22.7556H23.2556V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 21.3333H23.2556V22.7556H24.6778V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 21.3333H24.6778V22.7556H26.1V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 21.3333H26.1V22.7556H27.5222V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 21.3333H27.5222V22.7556H28.9444V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 21.3333H28.9444V22.7556H30.3667V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 21.3333H31.7889V22.7556H33.2111V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 21.3333H34.6333V22.7556H36.0556V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 21.3333H36.0556V22.7556H37.4778V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 21.3333H38.9V22.7556H40.3222V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 21.3333H40.3222V22.7556H41.7444V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 21.3333H48.8556V22.7556H50.2778V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 21.3333H51.7V22.7556H53.1222V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 21.3333H53.1222V22.7556H54.5444V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 21.3333H55.9667V22.7556H57.3889V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 21.3333H61.6556V22.7556H63.0778V21.3333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 22.7556H1.92222V24.1778H3.34444V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 22.7556H3.34444V24.1778H4.76667V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 22.7556H6.18889V24.1778H7.61111V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 22.7556H9.03333V24.1778H10.4556V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 22.7556H13.3V24.1778H14.7222V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 22.7556H20.4111V24.1778H21.8333V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 22.7556H26.1V24.1778H27.5222V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 22.7556H27.5222V24.1778H28.9444V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 22.7556H33.2111V24.1778H34.6333V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 22.7556H34.6333V24.1778H36.0556V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 22.7556H36.0556V24.1778H37.4778V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 22.7556H38.9V24.1778H40.3222V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 22.7556H41.7444V24.1778H43.1667V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 22.7556H46.0111V24.1778H47.4333V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 22.7556H48.8556V24.1778H50.2778V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 22.7556H51.7V24.1778H53.1222V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 22.7556H54.5444V24.1778H55.9667V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 22.7556H61.6556V24.1778H63.0778V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 22.7556H63.0778V24.1778H64.5V22.7556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 24.1778H3.34444V25.6H4.76667V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 24.1778H4.76667V25.6H6.18889V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 24.1778H6.18889V25.6H7.61111V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 24.1778H11.8778V25.6H13.3V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 24.1778H18.9889V25.6H20.4111V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 24.1778H23.2556V25.6H24.6778V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 24.1778H27.5222V25.6H28.9444V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 24.1778H28.9444V25.6H30.3667V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 24.1778H30.3667V25.6H31.7889V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 24.1778H31.7889V25.6H33.2111V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 24.1778H34.6333V25.6H36.0556V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 24.1778H36.0556V25.6H37.4778V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 24.1778H37.4778V25.6H38.9V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 24.1778H46.0111V25.6H47.4333V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 24.1778H50.2778V25.6H51.7V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 24.1778H51.7V25.6H53.1222V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 24.1778H55.9667V25.6H57.3889V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 24.1778H60.2333V25.6H61.6556V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 24.1778H61.6556V25.6H63.0778V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 24.1778H63.0778V25.6H64.5V24.1778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 25.6H3.34444V27.0222H4.76667V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 25.6H9.03333V27.0222H10.4556V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 25.6H11.8778V27.0222H13.3V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 25.6H17.5667V27.0222H18.9889V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 25.6H18.9889V27.0222H20.4111V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 25.6H20.4111V27.0222H21.8333V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 25.6H23.2556V27.0222H24.6778V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 25.6H24.6778V27.0222H26.1V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 25.6H28.9444V27.0222H30.3667V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 25.6H30.3667V27.0222H31.7889V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 25.6H33.2111V27.0222H34.6333V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 25.6H46.0111V27.0222H47.4333V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 25.6H47.4333V27.0222H48.8556V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 25.6H51.7V27.0222H53.1222V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 25.6H53.1222V27.0222H54.5444V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 25.6H54.5444V27.0222H55.9667V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 25.6H55.9667V27.0222H57.3889V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 25.6H61.6556V27.0222H63.0778V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 25.6H63.0778V27.0222H64.5V25.6Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 27.0222H6.18889V28.4444H7.61111V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 27.0222H7.61111V28.4444H9.03333V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 27.0222H14.7222V28.4444H16.1444V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 27.0222H18.9889V28.4444H20.4111V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 27.0222H26.1V28.4444H27.5222V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 27.0222H30.3667V28.4444H31.7889V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 27.0222H33.2111V28.4444H34.6333V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 27.0222H36.0556V28.4444H37.4778V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 27.0222H38.9V28.4444H40.3222V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 27.0222H40.3222V28.4444H41.7444V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 27.0222H41.7444V28.4444H43.1667V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 27.0222H48.8556V28.4444H50.2778V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 27.0222H54.5444V28.4444H55.9667V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 27.0222H57.3889V28.4444H58.8111V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 27.0222H60.2333V28.4444H61.6556V27.0222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 28.4444H0.5V29.8667H1.92222V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 28.4444H3.34444V29.8667H4.76667V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 28.4444H4.76667V29.8667H6.18889V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 28.4444H6.18889V29.8667H7.61111V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 28.4444H7.61111V29.8667H9.03333V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 28.4444H9.03333V29.8667H10.4556V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 28.4444H10.4556V29.8667H11.8778V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 28.4444H11.8778V29.8667H13.3V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 28.4444H14.7222V29.8667H16.1444V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 28.4444H18.9889V29.8667H20.4111V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 28.4444H20.4111V29.8667H21.8333V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 28.4444H23.2556V29.8667H24.6778V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 28.4444H24.6778V29.8667H26.1V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 28.4444H28.9444V29.8667H30.3667V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 28.4444H30.3667V29.8667H31.7889V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 28.4444H31.7889V29.8667H33.2111V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 28.4444H33.2111V29.8667H34.6333V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 28.4444H34.6333V29.8667H36.0556V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 28.4444H36.0556V29.8667H37.4778V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 28.4444H37.4778V29.8667H38.9V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 28.4444H38.9V29.8667H40.3222V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 28.4444H41.7444V29.8667H43.1667V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 28.4444H50.2778V29.8667H51.7V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 28.4444H51.7V29.8667H53.1222V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 28.4444H53.1222V29.8667H54.5444V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 28.4444H54.5444V29.8667H55.9667V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 28.4444H55.9667V29.8667H57.3889V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 28.4444H57.3889V29.8667H58.8111V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 28.4444H58.8111V29.8667H60.2333V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 28.4444H60.2333V29.8667H61.6556V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 28.4444H61.6556V29.8667H63.0778V28.4444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 29.8667H1.92222V31.2889H3.34444V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 29.8667H6.18889V31.2889H7.61111V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 29.8667H11.8778V31.2889H13.3V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 29.8667H14.7222V31.2889H16.1444V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 29.8667H18.9889V31.2889H20.4111V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 29.8667H20.4111V31.2889H21.8333V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 29.8667H21.8333V31.2889H23.2556V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 29.8667H23.2556V31.2889H24.6778V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 29.8667H26.1V31.2889H27.5222V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 29.8667H27.5222V31.2889H28.9444V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 29.8667H28.9444V31.2889H30.3667V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 29.8667H34.6333V31.2889H36.0556V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 29.8667H36.0556V31.2889H37.4778V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 29.8667H38.9V31.2889H40.3222V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 29.8667H41.7444V31.2889H43.1667V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 29.8667H48.8556V31.2889H50.2778V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 29.8667H50.2778V31.2889H51.7V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 29.8667H51.7V31.2889H53.1222V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 29.8667H57.3889V31.2889H58.8111V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 29.8667H63.0778V31.2889H64.5V29.8667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 31.2889H1.92222V32.7111H3.34444V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 31.2889H3.34444V32.7111H4.76667V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 31.2889H4.76667V32.7111H6.18889V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 31.2889H6.18889V32.7111H7.61111V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 31.2889H9.03333V32.7111H10.4556V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 31.2889H11.8778V32.7111H13.3V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 31.2889H18.9889V32.7111H20.4111V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 31.2889H20.4111V32.7111H21.8333V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 31.2889H24.6778V32.7111H26.1V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 31.2889H26.1V32.7111H27.5222V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 31.2889H27.5222V32.7111H28.9444V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 31.2889H28.9444V32.7111H30.3667V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 31.2889H31.7889V32.7111H33.2111V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 31.2889H34.6333V32.7111H36.0556V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 31.2889H41.7444V32.7111H43.1667V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 31.2889H43.1667V32.7111H44.5889V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 31.2889H44.5889V32.7111H46.0111V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 31.2889H46.0111V32.7111H47.4333V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 31.2889H47.4333V32.7111H48.8556V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 31.2889H48.8556V32.7111H50.2778V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 31.2889H50.2778V32.7111H51.7V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 31.2889H51.7V32.7111H53.1222V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 31.2889H54.5444V32.7111H55.9667V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 31.2889H57.3889V32.7111H58.8111V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 31.2889H58.8111V32.7111H60.2333V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 31.2889H60.2333V32.7111H61.6556V31.2889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 32.7111H6.18889V34.1333H7.61111V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 32.7111H11.8778V34.1333H13.3V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 32.7111H13.3V34.1333H14.7222V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 32.7111H14.7222V34.1333H16.1444V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 32.7111H17.5667V34.1333H18.9889V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 32.7111H18.9889V34.1333H20.4111V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 32.7111H20.4111V34.1333H21.8333V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 32.7111H21.8333V34.1333H23.2556V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 32.7111H24.6778V34.1333H26.1V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 32.7111H28.9444V34.1333H30.3667V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 32.7111H34.6333V34.1333H36.0556V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 32.7111H37.4778V34.1333H38.9V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 32.7111H40.3222V34.1333H41.7444V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 32.7111H43.1667V34.1333H44.5889V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 32.7111H44.5889V34.1333H46.0111V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 32.7111H47.4333V34.1333H48.8556V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 32.7111H48.8556V34.1333H50.2778V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 32.7111H51.7V34.1333H53.1222V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 32.7111H57.3889V34.1333H58.8111V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 32.7111H60.2333V34.1333H61.6556V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 32.7111H61.6556V34.1333H63.0778V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 32.7111H63.0778V34.1333H64.5V32.7111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 34.1333H4.76667V35.5556H6.18889V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 34.1333H6.18889V35.5556H7.61111V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 34.1333H7.61111V35.5556H9.03333V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 34.1333H9.03333V35.5556H10.4556V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 34.1333H10.4556V35.5556H11.8778V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 34.1333H11.8778V35.5556H13.3V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 34.1333H16.1444V35.5556H17.5667V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 34.1333H18.9889V35.5556H20.4111V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 34.1333H20.4111V35.5556H21.8333V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 34.1333H24.6778V35.5556H26.1V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 34.1333H26.1V35.5556H27.5222V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 34.1333H28.9444V35.5556H30.3667V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 34.1333H30.3667V35.5556H31.7889V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 34.1333H31.7889V35.5556H33.2111V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 34.1333H33.2111V35.5556H34.6333V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 34.1333H34.6333V35.5556H36.0556V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 34.1333H43.1667V35.5556H44.5889V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 34.1333H46.0111V35.5556H47.4333V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 34.1333H51.7V35.5556H53.1222V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 34.1333H53.1222V35.5556H54.5444V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 34.1333H54.5444V35.5556H55.9667V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 34.1333H55.9667V35.5556H57.3889V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 34.1333H57.3889V35.5556H58.8111V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 34.1333H60.2333V35.5556H61.6556V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 34.1333H61.6556V35.5556H63.0778V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 34.1333H63.0778V35.5556H64.5V34.1333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 35.5556H1.92222V36.9778H3.34444V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 35.5556H3.34444V36.9778H4.76667V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 35.5556H6.18889V36.9778H7.61111V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 35.5556H7.61111V36.9778H9.03333V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 35.5556H11.8778V36.9778H13.3V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 35.5556H14.7222V36.9778H16.1444V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 35.5556H17.5667V36.9778H18.9889V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 35.5556H18.9889V36.9778H20.4111V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 35.5556H23.2556V36.9778H24.6778V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 35.5556H24.6778V36.9778H26.1V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 35.5556H27.5222V36.9778H28.9444V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 35.5556H28.9444V36.9778H30.3667V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 35.5556H30.3667V36.9778H31.7889V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 35.5556H31.7889V36.9778H33.2111V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 35.5556H33.2111V36.9778H34.6333V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 35.5556H40.3222V36.9778H41.7444V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 35.5556H41.7444V36.9778H43.1667V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 35.5556H43.1667V36.9778H44.5889V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 35.5556H44.5889V36.9778H46.0111V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 35.5556H46.0111V36.9778H47.4333V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 35.5556H48.8556V36.9778H50.2778V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 35.5556H53.1222V36.9778H54.5444V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 35.5556H57.3889V36.9778H58.8111V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 35.5556H63.0778V36.9778H64.5V35.5556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 36.9778H0.5V38.4H1.92222V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 36.9778H1.92222V38.4H3.34444V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 36.9778H6.18889V38.4H7.61111V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 36.9778H9.03333V38.4H10.4556V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 36.9778H11.8778V38.4H13.3V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 36.9778H16.1444V38.4H17.5667V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 36.9778H17.5667V38.4H18.9889V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 36.9778H20.4111V38.4H21.8333V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 36.9778H23.2556V38.4H24.6778V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 36.9778H24.6778V38.4H26.1V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 36.9778H27.5222V38.4H28.9444V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 36.9778H28.9444V38.4H30.3667V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 36.9778H31.7889V38.4H33.2111V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 36.9778H34.6333V38.4H36.0556V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 36.9778H37.4778V38.4H38.9V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 36.9778H40.3222V38.4H41.7444V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 36.9778H41.7444V38.4H43.1667V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 36.9778H53.1222V38.4H54.5444V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 36.9778H55.9667V38.4H57.3889V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 36.9778H58.8111V38.4H60.2333V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 36.9778H61.6556V38.4H63.0778V36.9778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 38.4H1.92222V39.8222H3.34444V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 38.4H4.76667V39.8222H6.18889V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 38.4H6.18889V39.8222H7.61111V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 38.4H7.61111V39.8222H9.03333V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 38.4H10.4556V39.8222H11.8778V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 38.4H11.8778V39.8222H13.3V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 38.4H14.7222V39.8222H16.1444V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 38.4H18.9889V39.8222H20.4111V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 38.4H26.1V39.8222H27.5222V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 38.4H30.3667V39.8222H31.7889V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 38.4H33.2111V39.8222H34.6333V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 38.4H34.6333V39.8222H36.0556V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 38.4H36.0556V39.8222H37.4778V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 38.4H37.4778V39.8222H38.9V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 38.4H43.1667V39.8222H44.5889V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 38.4H44.5889V39.8222H46.0111V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 38.4H50.2778V39.8222H51.7V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 38.4H51.7V39.8222H53.1222V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 38.4H54.5444V39.8222H55.9667V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 38.4H57.3889V39.8222H58.8111V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 38.4H61.6556V39.8222H63.0778V38.4Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 39.8222H0.5V41.2444H1.92222V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 39.8222H9.03333V41.2444H10.4556V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 39.8222H11.8778V41.2444H13.3V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 39.8222H16.1444V41.2444H17.5667V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 39.8222H17.5667V41.2444H18.9889V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 39.8222H18.9889V41.2444H20.4111V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 39.8222H21.8333V41.2444H23.2556V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 39.8222H26.1V41.2444H27.5222V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 39.8222H28.9444V41.2444H30.3667V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 39.8222H30.3667V41.2444H31.7889V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 39.8222H33.2111V41.2444H34.6333V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 39.8222H34.6333V41.2444H36.0556V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 39.8222H36.0556V41.2444H37.4778V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 39.8222H37.4778V41.2444H38.9V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 39.8222H38.9V41.2444H40.3222V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 39.8222H43.1667V41.2444H44.5889V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 39.8222H46.0111V41.2444H47.4333V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 39.8222H48.8556V41.2444H50.2778V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 39.8222H50.2778V41.2444H51.7V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 39.8222H51.7V41.2444H53.1222V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 39.8222H53.1222V41.2444H54.5444V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 39.8222H54.5444V41.2444H55.9667V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 39.8222H55.9667V41.2444H57.3889V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 39.8222H60.2333V41.2444H61.6556V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 39.8222H63.0778V41.2444H64.5V39.8222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 41.2444H0.5V42.6667H1.92222V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 41.2444H6.18889V42.6667H7.61111V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 41.2444H13.3V42.6667H14.7222V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 41.2444H16.1444V42.6667H17.5667V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 41.2444H17.5667V42.6667H18.9889V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 41.2444H20.4111V42.6667H21.8333V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 41.2444H23.2556V42.6667H24.6778V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 41.2444H24.6778V42.6667H26.1V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 41.2444H28.9444V42.6667H30.3667V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 41.2444H30.3667V42.6667H31.7889V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 41.2444H31.7889V42.6667H33.2111V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 41.2444H33.2111V42.6667H34.6333V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 41.2444H34.6333V42.6667H36.0556V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 41.2444H37.4778V42.6667H38.9V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 41.2444H43.1667V42.6667H44.5889V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 41.2444H44.5889V42.6667H46.0111V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 41.2444H53.1222V42.6667H54.5444V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 41.2444H54.5444V42.6667H55.9667V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 41.2444H60.2333V42.6667H61.6556V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 41.2444H61.6556V42.6667H63.0778V41.2444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 42.6667H0.5V44.0889H1.92222V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 42.6667H1.92222V44.0889H3.34444V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 42.6667H3.34444V44.0889H4.76667V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 42.6667H6.18889V44.0889H7.61111V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 42.6667H7.61111V44.0889H9.03333V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 42.6667H9.03333V44.0889H10.4556V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 42.6667H17.5667V44.0889H18.9889V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 42.6667H28.9444V44.0889H30.3667V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 42.6667H34.6333V44.0889H36.0556V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 42.6667H40.3222V44.0889H41.7444V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 42.6667H46.0111V44.0889H47.4333V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 42.6667H50.2778V44.0889H51.7V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 42.6667H51.7V44.0889H53.1222V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 42.6667H57.3889V44.0889H58.8111V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 42.6667H61.6556V44.0889H63.0778V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 42.6667H63.0778V44.0889H64.5V42.6667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 44.0889H0.5V45.5111H1.92222V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 44.0889H1.92222V45.5111H3.34444V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 44.0889H4.76667V45.5111H6.18889V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 44.0889H10.4556V45.5111H11.8778V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 44.0889H14.7222V45.5111H16.1444V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 44.0889H16.1444V45.5111H17.5667V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 44.0889H20.4111V45.5111H21.8333V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 44.0889H21.8333V45.5111H23.2556V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 44.0889H24.6778V45.5111H26.1V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 44.0889H27.5222V45.5111H28.9444V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 44.0889H31.7889V45.5111H33.2111V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 44.0889H33.2111V45.5111H34.6333V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 44.0889H34.6333V45.5111H36.0556V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 44.0889H36.0556V45.5111H37.4778V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 44.0889H37.4778V45.5111H38.9V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 44.0889H40.3222V45.5111H41.7444V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 44.0889H41.7444V45.5111H43.1667V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 44.0889H44.5889V45.5111H46.0111V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 44.0889H46.0111V45.5111H47.4333V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 44.0889H47.4333V45.5111H48.8556V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 44.0889H50.2778V45.5111H51.7V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 44.0889H51.7V45.5111H53.1222V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 44.0889H53.1222V45.5111H54.5444V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 44.0889H54.5444V45.5111H55.9667V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 44.0889H55.9667V45.5111H57.3889V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 44.0889H57.3889V45.5111H58.8111V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 44.0889H58.8111V45.5111H60.2333V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 44.0889H63.0778V45.5111H64.5V44.0889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 45.5111H0.5V46.9333H1.92222V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 45.5111H3.34444V46.9333H4.76667V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 45.5111H7.61111V46.9333H9.03333V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 45.5111H9.03333V46.9333H10.4556V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 45.5111H10.4556V46.9333H11.8778V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 45.5111H14.7222V46.9333H16.1444V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 45.5111H17.5667V46.9333H18.9889V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 45.5111H21.8333V46.9333H23.2556V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 45.5111H23.2556V46.9333H24.6778V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 45.5111H26.1V46.9333H27.5222V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 45.5111H27.5222V46.9333H28.9444V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 45.5111H31.7889V46.9333H33.2111V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 45.5111H34.6333V46.9333H36.0556V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 45.5111H38.9V46.9333H40.3222V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 45.5111H40.3222V46.9333H41.7444V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 45.5111H43.1667V46.9333H44.5889V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 45.5111H44.5889V46.9333H46.0111V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 45.5111H47.4333V46.9333H48.8556V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 45.5111H51.7V46.9333H53.1222V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 45.5111H55.9667V46.9333H57.3889V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 45.5111H57.3889V46.9333H58.8111V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 45.5111H58.8111V46.9333H60.2333V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 45.5111H61.6556V46.9333H63.0778V45.5111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 46.9333H6.18889V48.3556H7.61111V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 46.9333H7.61111V48.3556H9.03333V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 46.9333H11.8778V48.3556H13.3V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 46.9333H14.7222V48.3556H16.1444V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 46.9333H18.9889V48.3556H20.4111V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 46.9333H20.4111V48.3556H21.8333V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 46.9333H23.2556V48.3556H24.6778V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 46.9333H30.3667V48.3556H31.7889V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 46.9333H31.7889V48.3556H33.2111V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 46.9333H34.6333V48.3556H36.0556V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 46.9333H37.4778V48.3556H38.9V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 46.9333H38.9V48.3556H40.3222V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 46.9333H41.7444V48.3556H43.1667V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 46.9333H47.4333V48.3556H48.8556V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 46.9333H48.8556V48.3556H50.2778V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 46.9333H50.2778V48.3556H51.7V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 46.9333H53.1222V48.3556H54.5444V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 46.9333H57.3889V48.3556H58.8111V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 46.9333H58.8111V48.3556H60.2333V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 46.9333H63.0778V48.3556H64.5V46.9333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 48.3556H6.18889V49.7778H7.61111V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 48.3556H9.03333V49.7778H10.4556V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M11.8778 48.3556H10.4556V49.7778H11.8778V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 48.3556H14.7222V49.7778H16.1444V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 48.3556H17.5667V49.7778H18.9889V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 48.3556H18.9889V49.7778H20.4111V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 48.3556H27.5222V49.7778H28.9444V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 48.3556H28.9444V49.7778H30.3667V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 48.3556H31.7889V49.7778H33.2111V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 48.3556H36.0556V49.7778H37.4778V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 48.3556H38.9V49.7778H40.3222V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 48.3556H44.5889V49.7778H46.0111V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 48.3556H46.0111V49.7778H47.4333V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 48.3556H48.8556V49.7778H50.2778V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 48.3556H50.2778V49.7778H51.7V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 48.3556H51.7V49.7778H53.1222V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 48.3556H53.1222V49.7778H54.5444V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 48.3556H57.3889V49.7778H58.8111V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 48.3556H61.6556V49.7778H63.0778V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 48.3556H63.0778V49.7778H64.5V48.3556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 49.7778H1.92222V51.2H3.34444V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 49.7778H3.34444V51.2H4.76667V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 49.7778H4.76667V51.2H6.18889V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 49.7778H6.18889V51.2H7.61111V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 49.7778H11.8778V51.2H13.3V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 49.7778H13.3V51.2H14.7222V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 49.7778H14.7222V51.2H16.1444V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 49.7778H16.1444V51.2H17.5667V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 49.7778H23.2556V51.2H24.6778V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 49.7778H24.6778V51.2H26.1V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 49.7778H26.1V51.2H27.5222V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 49.7778H28.9444V51.2H30.3667V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 49.7778H34.6333V51.2H36.0556V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 49.7778H37.4778V51.2H38.9V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 49.7778H43.1667V51.2H44.5889V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 49.7778H46.0111V51.2H47.4333V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 49.7778H47.4333V51.2H48.8556V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 49.7778H50.2778V51.2H51.7V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 49.7778H57.3889V51.2H58.8111V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 49.7778H58.8111V51.2H60.2333V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 49.7778H61.6556V51.2H63.0778V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 49.7778H63.0778V51.2H64.5V49.7778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 51.2H0.5V52.6222H1.92222V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 51.2H4.76667V52.6222H6.18889V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 51.2H6.18889V52.6222H7.61111V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 51.2H9.03333V52.6222H10.4556V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 51.2H14.7222V52.6222H16.1444V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 51.2H16.1444V52.6222H17.5667V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 51.2H20.4111V52.6222H21.8333V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 51.2H27.5222V52.6222H28.9444V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 51.2H28.9444V52.6222H30.3667V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 51.2H30.3667V52.6222H31.7889V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 51.2H31.7889V52.6222H33.2111V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 51.2H33.2111V52.6222H34.6333V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 51.2H34.6333V52.6222H36.0556V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 51.2H37.4778V52.6222H38.9V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 51.2H40.3222V52.6222H41.7444V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 51.2H43.1667V52.6222H44.5889V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 51.2H46.0111V52.6222H47.4333V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 51.2H47.4333V52.6222H48.8556V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 51.2H48.8556V52.6222H50.2778V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 51.2H51.7V52.6222H53.1222V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 51.2H53.1222V52.6222H54.5444V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 51.2H54.5444V52.6222H55.9667V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 51.2H55.9667V52.6222H57.3889V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 51.2H57.3889V52.6222H58.8111V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 51.2H58.8111V52.6222H60.2333V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 51.2H60.2333V52.6222H61.6556V51.2Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 52.6222H11.8778V54.0444H13.3V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 52.6222H13.3V54.0444H14.7222V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 52.6222H17.5667V54.0444H18.9889V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 52.6222H20.4111V54.0444H21.8333V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 52.6222H24.6778V54.0444H26.1V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 52.6222H26.1V54.0444H27.5222V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 52.6222H27.5222V54.0444H28.9444V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 52.6222H28.9444V54.0444H30.3667V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 52.6222H34.6333V54.0444H36.0556V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 52.6222H36.0556V54.0444H37.4778V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 52.6222H40.3222V54.0444H41.7444V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 52.6222H41.7444V54.0444H43.1667V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 52.6222H47.4333V54.0444H48.8556V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 52.6222H48.8556V54.0444H50.2778V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 52.6222H51.7V54.0444H53.1222V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 52.6222H57.3889V54.0444H58.8111V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 52.6222H58.8111V54.0444H60.2333V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 52.6222H63.0778V54.0444H64.5V52.6222Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 54.0444H0.5V55.4667H1.92222V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 54.0444H1.92222V55.4667H3.34444V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 54.0444H3.34444V55.4667H4.76667V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 54.0444H4.76667V55.4667H6.18889V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 54.0444H6.18889V55.4667H7.61111V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 54.0444H7.61111V55.4667H9.03333V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 54.0444H9.03333V55.4667H10.4556V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 54.0444H16.1444V55.4667H17.5667V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 54.0444H17.5667V55.4667H18.9889V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 54.0444H20.4111V55.4667H21.8333V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 54.0444H23.2556V55.4667H24.6778V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 54.0444H24.6778V55.4667H26.1V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 54.0444H28.9444V55.4667H30.3667V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 54.0444H31.7889V55.4667H33.2111V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 54.0444H34.6333V55.4667H36.0556V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 54.0444H38.9V55.4667H40.3222V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 54.0444H43.1667V55.4667H44.5889V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 54.0444H44.5889V55.4667H46.0111V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 54.0444H46.0111V55.4667H47.4333V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 54.0444H48.8556V55.4667H50.2778V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 54.0444H51.7V55.4667H53.1222V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 54.0444H54.5444V55.4667H55.9667V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 54.0444H57.3889V55.4667H58.8111V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 54.0444H60.2333V55.4667H61.6556V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 54.0444H63.0778V55.4667H64.5V54.0444Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 55.4667H0.5V56.8889H1.92222V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 55.4667H9.03333V56.8889H10.4556V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M13.3 55.4667H11.8778V56.8889H13.3V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 55.4667H16.1444V56.8889H17.5667V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 55.4667H21.8333V56.8889H23.2556V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 55.4667H27.5222V56.8889H28.9444V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 55.4667H28.9444V56.8889H30.3667V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 55.4667H34.6333V56.8889H36.0556V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 55.4667H36.0556V56.8889H37.4778V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 55.4667H37.4778V56.8889H38.9V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 55.4667H38.9V56.8889H40.3222V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 55.4667H40.3222V56.8889H41.7444V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 55.4667H44.5889V56.8889H46.0111V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 55.4667H46.0111V56.8889H47.4333V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 55.4667H47.4333V56.8889H48.8556V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 55.4667H51.7V56.8889H53.1222V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 55.4667H57.3889V56.8889H58.8111V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 55.4667H61.6556V56.8889H63.0778V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 55.4667H63.0778V56.8889H64.5V55.4667Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 56.8889H0.5V58.3111H1.92222V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 56.8889H3.34444V58.3111H4.76667V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 56.8889H4.76667V58.3111H6.18889V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 56.8889H6.18889V58.3111H7.61111V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 56.8889H9.03333V58.3111H10.4556V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 56.8889H13.3V58.3111H14.7222V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 56.8889H16.1444V58.3111H17.5667V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M18.9889 56.8889H17.5667V58.3111H18.9889V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 56.8889H18.9889V58.3111H20.4111V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 56.8889H21.8333V58.3111H23.2556V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 56.8889H28.9444V58.3111H30.3667V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 56.8889H30.3667V58.3111H31.7889V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 56.8889H31.7889V58.3111H33.2111V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 56.8889H33.2111V58.3111H34.6333V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 56.8889H34.6333V58.3111H36.0556V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 56.8889H36.0556V58.3111H37.4778V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 56.8889H37.4778V58.3111H38.9V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M40.3222 56.8889H38.9V58.3111H40.3222V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 56.8889H40.3222V58.3111H41.7444V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 56.8889H43.1667V58.3111H44.5889V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 56.8889H44.5889V58.3111H46.0111V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 56.8889H51.7V58.3111H53.1222V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 56.8889H53.1222V58.3111H54.5444V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 56.8889H54.5444V58.3111H55.9667V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M57.3889 56.8889H55.9667V58.3111H57.3889V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 56.8889H57.3889V58.3111H58.8111V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 56.8889H58.8111V58.3111H60.2333V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 56.8889H60.2333V58.3111H61.6556V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 56.8889H61.6556V58.3111H63.0778V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 56.8889H63.0778V58.3111H64.5V56.8889Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 58.3111H0.5V59.7333H1.92222V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 58.3111H3.34444V59.7333H4.76667V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 58.3111H4.76667V59.7333H6.18889V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 58.3111H6.18889V59.7333H7.61111V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 58.3111H9.03333V59.7333H10.4556V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 58.3111H14.7222V59.7333H16.1444V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M23.2556 58.3111H21.8333V59.7333H23.2556V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 58.3111H24.6778V59.7333H26.1V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 58.3111H27.5222V59.7333H28.9444V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 58.3111H28.9444V59.7333H30.3667V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 58.3111H33.2111V59.7333H34.6333V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 58.3111H34.6333V59.7333H36.0556V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 58.3111H41.7444V59.7333H43.1667V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 58.3111H43.1667V59.7333H44.5889V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 58.3111H46.0111V59.7333H47.4333V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 58.3111H48.8556V59.7333H50.2778V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 58.3111H50.2778V59.7333H51.7V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 58.3111H54.5444V59.7333H55.9667V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 58.3111H57.3889V59.7333H58.8111V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M60.2333 58.3111H58.8111V59.7333H60.2333V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M63.0778 58.3111H61.6556V59.7333H63.0778V58.3111Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 59.7333H0.5V61.1556H1.92222V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 59.7333H3.34444V61.1556H4.76667V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 59.7333H4.76667V61.1556H6.18889V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 59.7333H6.18889V61.1556H7.61111V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 59.7333H9.03333V61.1556H10.4556V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 59.7333H18.9889V61.1556H20.4111V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 59.7333H20.4111V61.1556H21.8333V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 59.7333H26.1V61.1556H27.5222V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 59.7333H31.7889V61.1556H33.2111V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 59.7333H37.4778V61.1556H38.9V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 59.7333H43.1667V61.1556H44.5889V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 59.7333H48.8556V61.1556H50.2778V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 59.7333H54.5444V61.1556H55.9667V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 59.7333H60.2333V61.1556H61.6556V59.7333Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 61.1556H0.5V62.5778H1.92222V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 61.1556H9.03333V62.5778H10.4556V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 61.1556H16.1444V62.5778H17.5667V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M21.8333 61.1556H20.4111V62.5778H21.8333V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 61.1556H23.2556V62.5778H24.6778V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M26.1 61.1556H24.6778V62.5778H26.1V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 61.1556H27.5222V62.5778H28.9444V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M30.3667 61.1556H28.9444V62.5778H30.3667V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 61.1556H31.7889V62.5778H33.2111V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M38.9 61.1556H37.4778V62.5778H38.9V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 61.1556H40.3222V62.5778H41.7444V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M43.1667 61.1556H41.7444V62.5778H43.1667V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 61.1556H43.1667V62.5778H44.5889V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M46.0111 61.1556H44.5889V62.5778H46.0111V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M47.4333 61.1556H46.0111V62.5778H47.4333V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 61.1556H48.8556V62.5778H50.2778V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M51.7 61.1556H50.2778V62.5778H51.7V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M55.9667 61.1556H54.5444V62.5778H55.9667V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 61.1556H60.2333V62.5778H61.6556V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M64.5 61.1556H63.0778V62.5778H64.5V61.1556Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M1.92222 62.5778H0.5V64H1.92222V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M3.34444 62.5778H1.92222V64H3.34444V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M4.76667 62.5778H3.34444V64H4.76667V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M6.18889 62.5778H4.76667V64H6.18889V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M7.61111 62.5778H6.18889V64H7.61111V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M9.03333 62.5778H7.61111V64H9.03333V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M10.4556 62.5778H9.03333V64H10.4556V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M14.7222 62.5778H13.3V64H14.7222V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M16.1444 62.5778H14.7222V64H16.1444V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M17.5667 62.5778H16.1444V64H17.5667V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M20.4111 62.5778H18.9889V64H20.4111V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M24.6778 62.5778H23.2556V64H24.6778V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M27.5222 62.5778H26.1V64H27.5222V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M28.9444 62.5778H27.5222V64H28.9444V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M31.7889 62.5778H30.3667V64H31.7889V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M33.2111 62.5778H31.7889V64H33.2111V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M34.6333 62.5778H33.2111V64H34.6333V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M36.0556 62.5778H34.6333V64H36.0556V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M37.4778 62.5778H36.0556V64H37.4778V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M41.7444 62.5778H40.3222V64H41.7444V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M44.5889 62.5778H43.1667V64H44.5889V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M48.8556 62.5778H47.4333V64H48.8556V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M50.2778 62.5778H48.8556V64H50.2778V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M53.1222 62.5778H51.7V64H53.1222V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M54.5444 62.5778H53.1222V64H54.5444V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M58.8111 62.5778H57.3889V64H58.8111V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
          <path
            d="M61.6556 62.5778H60.2333V64H61.6556V62.5778Z"
            fill="#2D2F42"
            fill-opacity="0.7"
          />
        </g>
        <defs>
          <clipPath id="clip0_189_611">
            <rect
              width="64"
              height="64"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="col-5 text-right">
      <div class="rw__fs-11 font-bold">الشروط والأحكام وسياسة الخصوصية</div>
      <div class="text-neutral-60">
        قم بمسح الرمز للإطلاع على جميع الشروط والأحكام
      </div>
    </div>
  </div>
  <!-- Page Title - End -->

  <!-- Terms Points - Start -->
  <div class="pt-2">
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Key Terms and Conditions</div>
        <div>
          The Terms and Conditions constitute a legally binding agreement
          between the customer and Rewaa for Information Technology. By using,
          subscribing to, or purchasing our services or products, you
          acknowledge your acceptance of our Terms & Conditions, and all
          policies published on our website, including the Privacy Policy. If
          you do not agree with these terms or any other Rewaa policies, you may
          submit a request for a refund of your subscription fee during the
          trial period (14 calendar days from the invoice date). Continuing to
          use Rewaa's services or products after the trial period is considered
          an explicit acceptance of these Terms and Conditions. Some of these
          terms include, but are not limited to:
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">أبرز الشروط والأحكام</div>
        <div class="rtl">
          الشروط والأحكام تمثل ملزمة قانونياً بين العميل وشركة رِواء التقنية
          لتقنية المعلومات. استخدامك لخدماتنا أو منتجاتنا أو الاشتراك فيها هو
          إقرار بقبولك هذه الشروط وكافة السياسات المنشورة على موقعنا، بما في ذلك
          سياسة الخصوصية. نوصيكم بمراجعتها للتأكد من أنها تتوافق مع توقعاتكم،
          يمكنكم الاطلاع عليها من خلال رمز الاستجابة السريع المرفق أو موقعنا
          الرسمي. في حال عدم موافقتكم على أي من الشروط أو السياسات، يمكنكم رفع
          طلب إرجاع لمبلغ الاشتراك خلال فترة التجربة (14 يومًا تقويميا من تاريخ
          فاتورة الشراء). استمراركم في استخدام خدمات أو منتجات رواء بعد انتهاء
          فترة التجربة يُعد موافقة صريحة وقبولًا ضمنيًا لهذه الشروط والأحكام.
          ونورد بعضها هنا على سبيل المثال لا الحصر:
        </div>
      </div>
    </div>
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Software Operation Requirements</div>
        <div>
          The customer must ensure a suitable environment for operating the
          software, including providing the necessary hardware and high-speed
          internet. Click here for more details.
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">متطلبات تشغيل الخدمة</div>
        <div class="rtl">
          يجب على العميل تأمين البيئة المناسبة لتشغيل البرنامج، بما في ذلك توفير
          الأجهزة اللازمة والإنترنت عالي السرعة. لمزيد من التفاصيل، يرجى الضغط
          على هذا الرابط.
        </div>
      </div>
    </div>
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Subscription Start Date</div>
        <div>
          The subscription begins from the date of the purchase invoice
          regardless of the date the hardware or/and any other devices are
          received. The subscription continues according to the package
          selected, and Rewaa is not liable for any delay caused by the customer
          in operating their business or operations.
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">تاريخ بدء الاشتراك</div>
        <div class="rtl">
          يبدأ تاريخ الاشتراك اعتباراً من تاريخ إصدار فاتورة شراء، بغض النظر عن
          تاريخ استلام الأجهزة أو البدء بتجربة منصة رواء. ويستمر الاشتراك وفقًا
          للباقة التي تم الاشتراك بها، ولا تتحمل شركة رواء أي مسؤولية في حال
          تأخر العميل في تشغيل تجارته أو عملياته.
        </div>
      </div>
    </div>
    ر:
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Device Warranty</div>
        <div>
          Rewaa devices are covered by a two-year (2) warranty from the date of
          the purchase invoice.
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">ضمان الأجهزة</div>
        <div class="rtl">
          ضمان أجهزة رِواء لمدة سنتين (2) من تاريخ فاتورة الشراء، ويشمل إصلاح أو
          استبدال الجهاز المتضرر بسبب العيوب التصنيعية بدون تكاليف إضافية.
        </div>
      </div>
    </div>
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Refund Policy</div>
        <div>
          Rewaa is committed to refunding any amounts paid for products or
          services within the 14-calendar-day trial period from the date of the
          purchase invoice. The refund request will be processed and the amount
          refunded within 30 calendar days from the date of approval of the
          return request or receipt of the devices with their accessories,
          including packaging.
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">سياسة الإرجاع</div>
        <div class="rtl">
          تلتزم شركة رِواء التقنية باسترداد أي مبلغ مدفوع على المنتجات أو
          الخدمات خلال فترة التجربة التي تمتد لـ 14 يوما تقويميا من تاريخ إصدار
          فاتورة الشراء. يتم معالجة طلب الإرجاع وإعادة المبلغ خلال 30 يوما
          تقويميا من تاريخ اعتماد طلب الإرجاع أو استلام الأجهزة بكامل ملحقاتها،
          بما في ذلك الكرتون.
        </div>
      </div>
    </div>
    <div class="terms-row">
      <div class="tg-col">
        <div class="font-bold mb-1">Technical Support</div>
        <div>
          Rewaa provides remote technical support exclusively through official
          communication channels, without any obligation to perform on-site
          visits or operational tasks on behalf of the client. Technical support
          is available 24/7 to assist you via live chat within the platform,
          toll-free number: 8001244020, or email: info&#64;rewaatech.com
        </div>
      </div>
      <div class="tg-col">
        <div class="font-bold mb-1">الدعم الفني</div>
        <div class="rtl">
          تقدم شركة رِواء الدعم الفني عن بُعد عبر قنوات التواصل الرسمية فقط، دون
          أي التزام بإجراء الزيارات الميدانية أو الأعمال التشغيلية نيابة عن
          العميل. الدعم الفني متاح لمساعدتك على مدار الساعة 24/7 عبر الدردشة
          الحية من داخل المنصة أو الرقم الموحد 8001244020 أو البريد الإلكتروني
          info&#64;rewaatech.com
        </div>
      </div>
    </div>
  </div>
  <!-- Terms Points - End -->

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9"></div>
      <div class="col-3">
        <div class="text-right">
          Page {{ info.taxInvoicePages + 2 }} of
          {{ info.taxInvoicePages + 2 }} -
          {{ getInvoiceId }}
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer - End -->
</div>
<!-- Terms & Conditions - End -->

<!-- <div class="py-6"></div> -->

<div id="credit-page"></div>

<!-- Credit Page - Start -->
<div class="hide">
  <!-- Header - Start -->
  <header class="grid pb-3">
    <div class="col-5">
      <div class="fs-22 font-semibold">Credit Note</div>
      <div class="rw__fs-7 font-normal text-neutral-60">إشعار دائن</div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-emblem-logo.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-neutral-60 text-right">
      <div>شركة رواء التقنية لتقنية المعلومات</div>
      <div>٣٢٥٩، انس بن مالك، ٨٣٨٤ حي الملقا، ١٣٥٢٢ الرياض</div>
      <div>المملكة العربية السعودية</div>
      <div>CR: 31018820003</div>
      <div>TRN: 310188259700003</div>
    </div>
  </header>

  <!-- Invoice & Buyer Info - Start -->
  <div class="grid">
    <div class="col-3">
      <div class="font-medium">Bill to</div>
      <div class="font-medium text-neutral-60 mb-1">العميل</div>
      <div>مؤسسة عبدالرحمن هلال للمقاولات والترقيع شركة شخص واحد</div>
      <div>
        3575 Al Feidh, Irqah Unit 123456789011 No Kingdom of Saudi Arabia
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Buyer Tax Number</div>
          <div class="font-medium text-neutral-60 mb-1">
            رقم السجل الضريبي للعميل
          </div>
          <div>264867547786453</div>
        </div>
        <div>
          <div class="font-medium">Buyer ID: CR</div>
          <div class="font-medium text-neutral-60 mb-1">
            معرّف العميل: سجل تجاري
          </div>
          <div>1234567890</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Credit Note Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم إشعار دائن</div>
          <div>CN-000101</div>
        </div>
        <div>
          <div class="font-medium">Credit Note Issue Date</div>
          <div class="font-medium text-neutral-60 mb-1">
            تاريخ إصدار إشعار دائن
          </div>
          <div>23 Dec 2024, 07:35:59 AM</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2 text-right">
        <div>
          <div class="font-medium">Invoice Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم الفاتورة</div>
          <div>INV-000100</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Highlight - Start -->
  <div class="text-right pt-2 border-top-1 border-neutral-20">
    <div class="font-medium">Total Credit Note Amount</div>
    <div class="font-medium text-neutral-60">إجمالي قيمة إشعار الدائن</div>
    <div class="flex gap-1 align-items-center justify-content-end">
      <div class="line-height-1">
        <div class="rw__fs-9">SAR</div>
        <div class="rw__fs-11 text-neutral-60">ر.س</div>
      </div>
      <div class="rw__fs-5">2300.00</div>
    </div>
  </div>

  <!-- Invoice Item Line Table - Start-->
  <div class="pb-2 pt-3">
    <table class="print-table print-border-table print-item-table">
      <thead>
        <tr>
          <th class="text-left">
            <div class="font-medium text-neutral-60">#</div>
          </th>
          <th>
            <div class="font-medium">Item / Description</div>
            <div class="font-medium text-neutral-60">الوصف / المنتج</div>
          </th>
          <th>
            <div class="font-medium">Price</div>
            <div class="font-medium text-neutral-60">سعر الوحدة</div>
          </th>
          <th>
            <div class="font-medium">Quantity</div>
            <div class="font-medium text-neutral-60">الكمية</div>
          </th>
          <th>
            <div class="font-medium">Discount</div>
            <div class="font-medium text-neutral-60">خصم</div>
          </th>
          <th>
            <div class="font-medium">Taxable Amount</div>
            <div class="font-medium text-neutral-60">المبلغ الخاضع للضريبة</div>
          </th>
          <th>
            <div class="font-medium">VAT</div>
            <div class="font-medium text-neutral-60">القيمة المضافة</div>
          </th>
          <th>
            <div class="font-medium">Amount</div>
            <div class="font-medium text-neutral-60">المجموع</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left">
            <div class="font-medium text-neutral-60">1</div>
          </td>
          <td class="text-neutral-60">
            <div class="font-semibold">
              <div>Basic Plan (1 year)</div>
              <div>اشتراك باقة أساسية (سنة)</div>
            </div>
            <div>1 Jan 2023-31 Dec 2024</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>x3</div>
          </td>
          <td>
            <div>-4000</div>
            <div class="text-neutral-60">60%</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>300</div>
            <div class="text-neutral-60">15%</div>
          </td>
          <td>
            <div>2300</div>
          </td>
        </tr>
        <tr>
          <td class="text-left">
            <div class="font-medium text-neutral-60">1</div>
          </td>
          <td class="text-neutral-60">
            <div class="font-semibold">
              <div>Basic Plan (1 year)</div>
              <div>اشتراك باقة أساسية (سنة)</div>
            </div>
            <div>1 Jan 2023-31 Dec 2024</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>x3</div>
          </td>
          <td>
            <div>-4000</div>
            <div class="text-neutral-60">60%</div>
          </td>
          <td>
            <div>2000</div>
          </td>
          <td>
            <div>300</div>
            <div class="text-neutral-60">15%</div>
          </td>
          <td>
            <div>2300</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Notes & Calculation Summary - Start -->
  <div class="grid">
    <div class="col-6">
      <div class="font-medium">Notes</div>
      <div class="font-medium text-neutral-60 mb-1">ملاحظات</div>
      <div>-</div>
    </div>
    <div class="col-6">
      <table class="print-table print-item-table">
        <tbody>
          <tr>
            <td>
              <div class="font-medium">Subtotal</div>
              <div class="font-medium text-neutral-60">
                الإجمالي غير شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>6000</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Discount</div>
              <div class="font-medium text-neutral-60">إجمالي الخصم</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>-4000</div>
              <div class="text-neutral-60">60%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Taxable Amount</div>
              <div class="font-medium text-neutral-60">
                إجمالي المبلغ الخاضع للضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>2000</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total VAT</div>
              <div class="font-medium text-neutral-60">
                إجمالي ضريبة القيمة المضافة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>300</div>
              <div class="text-neutral-60">15%</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total</div>
              <div class="font-medium text-neutral-60">
                الإجمالي شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-medium">2300</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9">
        <img
          src="/assets/images/invoice-pdf-qr.svg"
          alt=""
        />
        <div class="mt-2">
          This QR code is encoded as per ZATCA's invoicing requirements
        </div>
        <div>
          رمز الإستجابة السريعة مشفّر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
          للفوترة الإلكترونية
        </div>
      </div>
      <div class="col-3">
        <div class="text-right">Page 1 of 1 - CN-000101</div>
      </div>
    </div>
  </footer>
</div>

<!-- Credit Page - End -->

<!-- <div class="py-6"></div> -->

<div id="payment-summary-page"></div>

<!-- Payment Page - Start -->
<div class="hide">
  <!-- Header - Start -->
  <header class="grid pb-3">
    <div class="col-5">
      <div class="fs-22 font-semibold">Payment Summary</div>
      <div class="rw__fs-7 font-normal text-neutral-60">ملخّص المدفوعات</div>
    </div>
    <div class="col-2">
      <img
        class="mx-auto"
        src="/assets/images/rewaa-emblem-logo.svg"
        alt=""
      />
    </div>
    <div class="col-5 text-neutral-60 text-right">
      <div>شركة رواء التقنية لتقنية المعلومات</div>
      <div>٣٢٥٩، انس بن مالك، ٨٣٨٤ حي الملقا، ١٣٥٢٢ الرياض</div>
      <div>المملكة العربية السعودية</div>
      <div>CR: 31018820003</div>
      <div>TRN: 310188259700003</div>
    </div>
  </header>
  <!-- Header - End -->

  <!-- Invoice & Buyer Info - Start -->
  <div class="grid">
    <div class="col-3">
      <div class="font-medium">Customer</div>
      <div class="font-medium text-neutral-60 mb-1">العميل</div>
      <div>مؤسسة عبدالرحمن هلال للمقاولات والترقيع شركة شخص واحد</div>
      <div>
        3575 Al Feidh, Irqah Unit 123456789011 No Kingdom of Saudi Arabia
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Buyer Tax Number</div>
          <div class="font-medium text-neutral-60 mb-1">
            رقم السجل الضريبي للعميل
          </div>
          <div>264867547786453</div>
        </div>
        <div>
          <div class="font-medium">Buyer ID: CR</div>
          <div class="font-medium text-neutral-60 mb-1">
            معرّف العميل: سجل تجاري
          </div>
          <div>1234567890</div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Invoice Number</div>
          <div class="font-medium text-neutral-60 mb-1">رقم الفاتورة</div>
          <div>INV-000100</div>
        </div>
      </div>
    </div>
    <div class="col-3 text-right">
      <div class="flex flex-column gap-2">
        <div>
          <div class="font-medium">Last Updated</div>
          <div class="font-medium text-neutral-60 mb-1">تاريخ آخر تحديث</div>
          <div>23 Dec 2024, 07:35:59 AM</div>
        </div>
      </div>
    </div>
  </div>
  <!-- Invoice & Buyer Info - End -->

  <!-- Invoice Item Line Table - Start-->
  <div class="pb-2 pt-6">
    <table class="print-table print-border-table payment-table">
      <thead>
        <tr>
          <th class="text-left">
            <div class="font-medium">Transaction Date</div>
            <div class="font-medium text-neutral-60">تاريخ العملية</div>
          </th>
          <th class="text-left">
            <div class="font-medium">Payment Method</div>
            <div class="font-medium text-neutral-60">طريقة الدفع</div>
          </th>
          <th class="text-left">
            <div class="font-medium">Reference No.</div>
            <div class="font-medium text-neutral-60">الرقم المرجعي</div>
          </th>
          <th>
            <div class="font-medium">Amount Paid</div>
            <div class="font-medium text-neutral-60">المبلغ المدفوع</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left">
            <div class="font-semibold text-neutral-60">23 Dec 2024</div>
          </td>
          <td class="text-left">
            <div>Bank Transfer</div>
            <div class="text-neutral-60">Al Rajhi Bank</div>
          </td>
          <td class="text-left">
            <div>txn_169xRMUPwwaVhEB1U</div>
          </td>
          <td>
            <div class="font-semibold">800</div>
          </td>
        </tr>
        <tr>
          <td class="text-left">
            <div class="font-semibold text-neutral-60">23 Dec 2024</div>
          </td>
          <td class="text-left">
            <div>Credit Card</div>
            <div class="text-neutral-60">Visa</div>
          </td>
          <td class="text-left">
            <div>txn_169xRMUPwwaVhEB1U</div>
          </td>
          <td>
            <div class="font-semibold">800</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Invoice Item Line Table - End-->

  <!-- Notes & Calculation Summary - Start -->
  <div class="grid">
    <div class="col-6">
      <div class="font-medium">Notes</div>
      <div class="font-medium text-neutral-60 mb-1">ملاحظات</div>
      <div>-</div>
    </div>
    <div class="col-6">
      <table class="print-table print-item-table">
        <tbody>
          <tr>
            <td>
              <div class="font-medium">Invoice Total</div>
              <div class="font-medium text-neutral-60">
                إجمالي الفاتورة شامل الضريبة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>2300</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Total Collected Amount</div>
              <div class="font-medium text-neutral-60">
                إجمالي المبالغ المحصّلة
              </div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div>-2000</div>
            </td>
          </tr>
          <tr class="fs-7 text-neutral-60">
            <td class="pt-0">
              <div>Total Amount Paid</div>
              <div class="font-medium">إجمالي المبالغ المدفوعة</div>
            </td>
            <td class="pt-0">
              <div>SAR</div>
              <div>ر.س</div>
            </td>
            <td class="pt-0">
              <div>1800</div>
            </td>
          </tr>
          <tr class="fs-7 text-neutral-60">
            <td class="pt-0">
              <div>Customer Credit</div>
              <div>رصيد العميل</div>
            </td>
            <td class="pt-0">
              <div>SAR</div>
              <div>ر.س</div>
            </td>
            <td class="pt-0">
              <div>200</div>
            </td>
          </tr>
          <tr class="border-top-1 border-neutral-20">
            <td>
              <div class="font-medium">Total Due</div>
              <div class="font-medium text-neutral-60">إجمالي المستحق</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-semibold">300</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="font-medium">Excess Balance</div>
              <div class="font-medium text-neutral-60">رصيد زائد</div>
            </td>
            <td>
              <div>SAR</div>
              <div class="font-medium text-neutral-60">ر.س</div>
            </td>
            <td>
              <div class="font-medium">0</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Notes & Calculation Summary - End -->

  @if (invoice?.dueAmount === 0) {
    <!-- Highlight Paid - Start -->
    <div class="flex gap-4 pt-2 justify-content-between">
      <div>
        <div class="font-medium">Payment Status</div>
        <div class="font-medium text-neutral-60">حالة الدفع</div>
        <div class="text-green-60 rw__fs-9 py-2">
          Paid In Full مدفوعة بالكامل
        </div>
      </div>
      <div class="text-right">
        <div class="font-medium">Total Due</div>
        <div class="font-medium text-neutral-60">إجمالي المستحق</div>
        <div class="flex gap-1 align-items-center justify-content-end">
          <div class="line-height-1">
            <div class="rw__fs-9">SAR</div>
            <div class="rw__fs-11 text-neutral-60">ر.س</div>
          </div>
          <div class="rw__fs-5">00</div>
        </div>
      </div>
    </div>
    <!-- Highlight Paid - End -->
  } @else {
    <!-- Highlight Unpaid - Start -->
    <div class="flex gap-4 pt-2 justify-content-between">
      <div>
        <div class="font-medium">Payment Status</div>
        <div class="font-medium text-neutral-60">حالة الدفع</div>
        <div class="text-red-60 rw__fs-9 py-2">Partially Paid مدفوع جزئياً</div>
      </div>
      <div class="text-right">
        <div class="font-medium">Total Due</div>
        <div class="font-medium text-neutral-60">إجمالي المستحق</div>
        <div
          class="flex gap-1 align-items-center justify-content-end text-red-60"
        >
          <div class="line-height-1">
            <div class="rw__fs-9">SAR</div>
            <div class="rw__fs-11">ر.س</div>
          </div>
          <div class="rw__fs-5">{{ invoice?.dueAmount }}</div>
        </div>
      </div>
    </div>
    <!-- Highlight Unpaid - End -->
  }

  <!-- Footer - Start -->
  <footer class="text-neutral-60 pt-3">
    <div class="grid align-items-end">
      <div class="col-9">
        <img
          src="/assets/images/invoice-pdf-qr.svg"
          alt=""
        />
        <div class="mt-2">
          This QR code is encoded as per ZATCA's invoicing requirements
        </div>
        <div>
          رمز الإستجابة السريعة مشفّر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
          للفوترة الإلكترونية
        </div>
      </div>
      <div class="col-3">
        <div class="text-right">Page 1 of 1 - PS-000100</div>
      </div>
    </div>
  </footer>
  <!-- Footer - End -->
</div>
<!-- Payment Page - End -->
